import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, of, switchMap, tap, throwError } from 'rxjs';
import {
  ColumnRoleValue,
  ColumnTypeValues,
  ExpCol,
  ExpColCreateDto,
  ExperimentCellService,
  ExperimentColumnsService,
  ExperimentColumnStatsService,
  ExperimentRowsService,
  ExperimentsDto,
  ExperimentsService,
  ExpRow,
  ExpRowCreateDto,
  ExpVal,
  ExpValData,
  ImportDataService,
  Stats,
} from '../../../generated-code/logic-core-api';
import { ExperimentsRow } from '../../core/models/experiments-row.model';
import { ConfigurationSelectors } from '../configuration/configuration.selectors';
import { ProjectSelectors } from '../project/project.selectors';
import { ExperimentsHelperService } from './experiments-helper.service';
import {
  AddConcept,
  AddRow,
  CreateExperimentsFromScratch,
  DeleteConcept,
  HideColumn,
  LoadExperimentsByProjectId,
  LoadStats,
  RemoveRow,
  ResetExperimentsState,
  UpdateConcept,
  UpdateRow,
  UpdateValue,
  UploadData,
} from './experiments.actions';

const STATE_NAME = 'experiments';
export class ExperimentsStateModel {
  loadingExperiments!: boolean;
  error!: any | null;
  columns!: ExpCol[];
  rows!: ExpRow[];
  allValues!: ExpVal[];
  values!: ExperimentsRow[];
  newCol!: ExpCol | null;
  stats!: Stats | null;
  hiddenColumns!: any;
}
@State<ExperimentsStateModel>({
  name: STATE_NAME,
  defaults: {
    loadingExperiments: false,
    error: null,
    columns: [],
    rows: [],
    allValues: [],
    values: [],
    newCol: null,
    stats: null,
    hiddenColumns: {},
  },
})
@Injectable()
export class ExperimentsState {
  experimentAPIService = inject(ExperimentsService);
  exColAPIService = inject(ExperimentColumnsService);
  exRowAPIService = inject(ExperimentRowsService);
  exCellAPIService = inject(ExperimentCellService);
  importDataService = inject(ImportDataService);
  statsAPIService = inject(ExperimentColumnStatsService);

  httpClient = inject(HttpClient);
  store = inject(Store);
  experimentsHelperService = inject(ExperimentsHelperService);

  @Action(ResetExperimentsState)
  restState({ setState }: StateContext<ExperimentsStateModel>) {
    setState({
      loadingExperiments: false,
      error: null,
      columns: [],
      rows: [],
      allValues: [],
      values: [],
      newCol: null,
      stats: null,
      hiddenColumns: {},
    });
  }

  @Action(LoadExperimentsByProjectId)
  loadDataset({ patchState }: StateContext<ExperimentsStateModel>, { projectId }: LoadExperimentsByProjectId) {
    patchState({
      loadingExperiments: true,
    });

    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.experimentAPIService.apiV1ExperimentsProjectIdGet(projectId, tenantId).pipe(
      tap((experiments: ExperimentsDto) => {
        patchState({
          loadingExperiments: false,
          columns: experiments.columns || [],
          rows: experiments.rows || [],
          allValues: experiments.values || [],
          values: this.experimentsHelperService.createRows(experiments),
        });
      }),
      catchError((error) => {
        patchState({
          loadingExperiments: false,
          error,
          columns: [],
          rows: [],
          allValues: [],
          values: [],
          newCol: null,
        });
        return error;
      }),
    );
  }

  @Action(HideColumn)
  hideColum({ patchState, getState }: StateContext<ExperimentsStateModel>, { colId }: HideColumn) {
    return patchState({
      hiddenColumns: {
        ...getState().hiddenColumns,
        [colId]: !getState().hiddenColumns[colId],
      },
    });
  }

  @Action(CreateExperimentsFromScratch)
  createDataset({ patchState }: StateContext<ExperimentsStateModel>) {
    patchState({
      columns: [],
      rows: [],
      values: [],
    });
  }

  @Action(AddRow)
  addRow({ dispatch, patchState, getState }: StateContext<ExperimentsStateModel>) {
    patchState({
      loadingExperiments: true,
    });
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const projectId = project?.id;
    const columns = getState().columns;
    const values = columns.reduce((result: { [key: string]: ExpValData }, column: ExpCol) => {
      const val = null;
      result[column?.id || ''] = { val, uncertainty: 0 };
      return result;
    }, {});
    const newRow: ExpRowCreateDto = { values, projectId };
    if (projectId) {
      const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.exRowAPIService.apiV1ExperimentsProjectIdRowsPost(projectId, tenantId, newRow).pipe(
        switchMap(() => dispatch(new LoadExperimentsByProjectId(projectId))),
        catchError((error) => {
          patchState({
            loadingExperiments: false,
            error,
          });
          return error;
        }),
      );
    }
    return of();
  }

  @Action(RemoveRow)
  deleteRow({ dispatch, patchState, getState }: StateContext<ExperimentsStateModel>, { rowId }: RemoveRow) {
    patchState({
      loadingExperiments: true,
    });
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const projectId = project?.id;
    if (projectId) {
      const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.exRowAPIService.apiV1ExperimentsProjectIdRowsIdDelete(projectId, rowId, tenantId).pipe(
        tap((row: ExpRow) => {
          dispatch(new LoadExperimentsByProjectId(projectId));
        }),
        catchError((error) => {
          patchState({
            loadingExperiments: false,
            error,
          });
          return error;
        }),
      );
    }
    return of();
  }

  @Action(UpdateRow)
  updateRow({ dispatch, patchState, getState }: StateContext<ExperimentsStateModel>, { rowId, notes }: UpdateRow) {
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const rows = getState().allValues;
    const cols = getState().columns;
    const values = cols.reduce((result: { [key: string]: ExpValData }, column: ExpCol) => {
      const cell = rows.find((r) => r?.expRowId === rowId && r?.expColId === column.id);
      result[column?.id as string] = { val: cell?.val, uncertainty: cell?.uncertainty };
      return result;
    }, {});
    const updatedRow: ExpRowCreateDto = {
      projectId: project?.id,
      values,
      notes,
    };
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.exRowAPIService
      .apiV1ExperimentsProjectIdRowsIdPut(project?.id as string, rowId, tenantId, updatedRow)
      .pipe
      /*tap(() => {
          patchState({
            loadingExperiments: true,
          });
          dispatch(new LoadExperimentsByProjectId(project?.id as string));
        }),*/
      ();
  }

  @Action(UpdateValue)
  updateValue(
    { dispatch, patchState, getState }: StateContext<ExperimentsStateModel>,
    { rowId, colId, value }: UpdateValue,
  ) {
    const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
    const projectId = project?.id;
    const allValues = getState().allValues;
    const cell = allValues.find((r) => r?.expColId === colId && r?.expRowId === rowId);
    const newCell: ExpVal = {
      ...cell,
      val: value,
    };
    if (projectId && cell?.id) {
      patchState({
        loadingExperiments: true,
      });
      const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.exCellAPIService
        .apiV1ExperimentsProjectIdCellIdPut(projectId, cell.id as string, tenantId, newCell)
        .pipe(
          /*tap(() => {
            patchState({
              loadingExperiments: true,
            });
            dispatch(new LoadExperimentsByProjectId(projectId));
          }),*/
          catchError((error) => {
            patchState({
              loadingExperiments: true,
              error,
            });
            return error;
          }),
        );
    } else {
      return throwError(() => 'Cell not found');
    }
  }

  @Action(UploadData)
  uploadExcell(
    { dispatch, patchState }: StateContext<ExperimentsStateModel>,
    { projectId, file, type, separator }: UploadData,
  ) {
    patchState({
      loadingExperiments: true,
    });
    const formData: FormData = new FormData();
    formData.append('file', file);
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    let service!: 'apiV1ExperimentsProjectIdImportExcelPost' | 'apiV1ExperimentsProjectIdImportJsonPost';
    if (type === 'csv') {
      return this.importDataService
        .apiV1ExperimentsProjectIdImportCsvPost(projectId, tenantId, separator || ',', file)
        .pipe(
          tap((experiments: ExperimentsDto) => {
            dispatch(new LoadExperimentsByProjectId(projectId));
          }),
          catchError((error) => {
            patchState({
              loadingExperiments: false,
              error,
            });
            return error;
          }),
        );
    } else {
      if (type === 'excel') service = 'apiV1ExperimentsProjectIdImportExcelPost';
      if (type === 'json') service = 'apiV1ExperimentsProjectIdImportJsonPost';
      if (service) {
        return this.importDataService[service](projectId, tenantId, file).pipe(
          tap((experiments: ExperimentsDto) => {
            dispatch(new LoadExperimentsByProjectId(projectId));
          }),
          catchError((error) => {
            patchState({
              loadingExperiments: false,
              error,
            });
            return error;
          }),
        );
      }
      return of();
    }
  }

  @Action(AddConcept)
  addConcept({ patchState, getState }: StateContext<ExperimentsStateModel>, { projectId, concept }: AddConcept) {
    const newConcept: ExpColCreateDto = {
      projectId,
      name: concept?.name || 'New Concept',
      type: (concept?.type as ColumnTypeValues) || ColumnTypeValues.String,
      role: (concept?.role as ColumnRoleValue) || ColumnRoleValue.Undefined,
      precision: concept?.precision,
      notes: concept?.notes || '',
    };
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.exColAPIService.apiV1ExperimentsProjectIdColumnsPost(projectId, tenantId, newConcept).pipe(
      tap((excol: ExpCol) => {
        const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
        const projectId = project?.id;
        patchState({
          newCol: excol,
        });
        this.store.dispatch(new LoadExperimentsByProjectId(projectId as string));
      }),
    );
  }

  @Action(DeleteConcept)
  deleteConcept({ patchState, getState }: StateContext<ExperimentsStateModel>, { projectId, concept }: DeleteConcept) {
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.exColAPIService
      .apiV1ExperimentsProjectIdColumnsIdDelete(projectId, concept?.id as string, tenantId)
      .pipe(
        tap((excol: ExpCol) => {
          const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
          const projectId = project?.id;
          this.store.dispatch(new LoadExperimentsByProjectId(projectId as string));
        }),
      );
  }

  @Action(UpdateConcept)
  updateConcept({ patchState, getState }: StateContext<ExperimentsStateModel>, { projectId, concept }: UpdateConcept) {
    const newConcept: ExpColCreateDto = {
      projectId,
      name: concept?.name || 'New Concept',
      type: concept?.type as ColumnTypeValues,
      role: concept?.role as ColumnRoleValue,
      precision: concept?.precision,
      notes: concept?.notes || '',
    };
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    return this.exColAPIService
      .apiV1ExperimentsProjectIdColumnsIdPut(projectId, concept?.id as string, tenantId, newConcept)
      .pipe(
        tap(() => {
          const project = this.store.selectSnapshot(ProjectSelectors.selectedProject);
          const projectId = project?.id;
          this.store.dispatch(new LoadExperimentsByProjectId(projectId as string));
        }),
      );
  }

  @Action(LoadStats)
  loadStats({ patchState, getState }: StateContext<ExperimentsStateModel>, { projectId, colId }: LoadStats) {
    const tenant = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    patchState({
      stats: null,
    });
    return this.statsAPIService.apiV1ExperimentsProjectIdStatsExpColIdGet(projectId, colId, tenant).pipe(
      tap((stats: Stats) => {
        patchState({
          stats,
        });
      }),
    );
  }
}
