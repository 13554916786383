import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, Signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngxs/store';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SetMenuCollapse } from '../../../store/configuration/configuration.actions';
import { ConfigurationSelectors } from '../../../store/configuration/configuration.selectors';
import { OnlyAdminDirective } from '../../directives/only-admin.directive';
import { AuthService } from '../../services/auth.service';
import { DexterEventType, GlobalEventsService } from '../../services/global-events.service';
import { PortalsBridgeService } from '../../services/portal-bridge.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NzBreadCrumbModule,
    NzInputModule,
    NzIconModule,
    NzLayoutModule,
    NzGridModule,
    NzButtonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzToolTipModule,
    PortalModule,
    OnlyAdminDirective,
    TranslocoModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  store = inject(Store);
  authService = inject(AuthService);
  router = inject(Router);
  geService = inject(GlobalEventsService);
  portalsBridge = inject(PortalsBridgeService);
  translocoService = inject(TranslocoService);
  isCollapsed = select(ConfigurationSelectors.isMenuCollapsed);
  user = select(ConfigurationSelectors.user);
  activeTenant = select(ConfigurationSelectors.activeTenant);
  portal!: Signal<TemplatePortal | null>;
  portalBreadcrumbs!: Signal<TemplatePortal | null>;

  initials = computed(() => {
    const user = this.user()?.user;
    return user ? `${user?.firstName?.[0]}${user?.lastName?.[0]}` : '';
  });
  filterForm = new FormControl('');

  breadcrumb = select(ConfigurationSelectors.breadcrumb);

  constructor() {
    this.filterForm.valueChanges.subscribe((value) => {
      this.geService.emit(DexterEventType.FILTER_CHANGED, value);
    });
  }

  ngOnInit(): void {
    this.portal = this.portalsBridge.headerPortal;
    this.portalBreadcrumbs = this.portalsBridge.breadcrumbsPortal;
  }

  changeLang(lang: string): void {
    this.translocoService.setActiveLang(lang);
  }

  goToAdmin() {
    const isSuperAdmin = this.store.selectSnapshot(ConfigurationSelectors.isSuperAdmin);
    if (isSuperAdmin) {
      return this.router.navigate(['/admin']);
    }
    const isAdmin = this.store.selectSnapshot(ConfigurationSelectors.isAdmin);
    if (isAdmin) {
      const organizationId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.router.navigate(['/admin/organization', organizationId]);
    }
    return;
  }

  toggleCollapsed(): void {
    this.store.dispatch(new SetMenuCollapse(!this.isCollapsed()));
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
