<ng-container *transloco="let t">
  <div class="app-header fxRow" nz-row nzJustify="start" nzAlign="middle">
    <span nz-col class="header-trigger">
      <button nz-button nzType="text" (click)="toggleCollapsed()" nzShape="circle" data-testid="sidebar-btn">
        <span class="trigger" nz-icon [nzType]="isCollapsed() ? 'menu-unfold' : 'menu-fold'"></span>
      </button>
    </span>
    <div>
      <nz-breadcrumb class="hideSm" nz-col nzFlex="auto">
        @for (item of breadcrumb(); track item) {
          <nz-breadcrumb-item>
            @if (item.url) {
              <a [routerLink]="item.url" [queryParams]="item.params">{{ item.label }}</a>
            } @else {
              {{ item.label }}
            }
          </nz-breadcrumb-item>
        }
      </nz-breadcrumb>
    </div>
    <div>
      <ng-template [cdkPortalOutlet]="portalBreadcrumbs()"></ng-template>
    </div>
    <div class="flex-1 flex justify-content-end align-items-center">
      <!-- HEADER PORTAL-->
      <ng-template [cdkPortalOutlet]="portal()"></ng-template>
    </div>

    <div nz-col>
      <button
        nz-button
        nzType="default"
        [nzTooltipTitle]="t('core.projects')"
        nzTooltipPlacement="bottom"
        nz-tooltip
        nzShape="circle"
        class="color-secondary"
        [routerLink]="['/projects', activeTenant()?.id]">
        <span nz-icon nzType="partition" nzTheme="outline" style="font-size: 16px"></span>
      </button>
    </div>
    <div nz-col appOnlyAdmin>
      <button
        nz-button
        nzType="default"
        nzTooltipTitle="Organizations"
        nzTooltipPlacement="bottom"
        nz-tooltip
        nzShape="circle"
        class="color-secondary"
        (click)="goToAdmin()">
        <span nz-icon nzType="setting" nzTheme="outline" style="font-size: 16px"></span>
      </button>
    </div>
    <div class="avatar-container cursor-pointer" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
      <div class="avatar avatar-sm">{{ initials() }}</div>
      <span class="outer-left-nano" nz-icon nzType="down" nzTheme="outline" style="font-size: 10px; color: #444"></span>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="user-menu inner-y-micro">
      <div class="fxRow outer-bottom-micro outer-x-nano">
        <div class="avatar">{{ initials() }}</div>
        <div>
          <div class="name">{{ user()?.user?.firstName }} {{ user()?.user?.lastName }}</div>
          <div class="role">{{ user()?.user?.role }}</div>
        </div>
      </div>
      <div>
        <div>
          <a class="menu-item color-secondary" routerLink="/profile" nz-button nzType="link">
            <span nz-icon nzType="user" nzTheme="outline"></span>
            <span>{{ t('section.profile') }}</span>
          </a>
        </div>
        <div class="separator"></div>
        <div>
          <a class="menu-item color-secondary" nz-button nzType="link" (click)="changeLang('en')">
            <span>English</span>
          </a>
        </div>
        <div>
          <a class="menu-item color-secondary" nz-button nzType="link" (click)="changeLang('es')">
            <span>Español</span>
          </a>
        </div>
        <div class="separator"></div>
        <div class="panel-b-t">
          <a class="menu-item" (click)="logout()" nz-button nzType="link" nzDanger>
            <span nz-icon nzType="poweroff" nzTheme="outline"></span>
            <span>{{ t('core.logout') }}</span>
          </a>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>
</ng-container>
