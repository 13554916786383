import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, EMPTY, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NzNotificationService);
  const router = inject(Router);
  const authService = inject(AuthService);

  const isLogin = req.url.endsWith('/token');

  return next(req).pipe(
    catchError((error) => {
      let errorMessage = '';
      if (error instanceof ErrorEvent) {
        errorMessage = `${error.error.message}`;
      } else {
        const serverError = error.error || error;
        errorMessage = `${error.status} - ${serverError.error_description}`;
      }
      console.error(error.status, errorMessage);
      if ([401, 403, 0].includes(error.status)) {
        if (!isLogin) {
          authService.logout();
          router.navigate(['/login']);
        } else {
          authService.logout();
          notificationService.create('error', 'Error', `${errorMessage}`, { nzDuration: 5000 });
        }
        /*if (authService.hasToken()) {
          authService.logout();
          router.navigate(['/error', error.status]);
        } else {
          authService.logout();
          router.navigate(['/login']);
        }*/
        return EMPTY;
      } else {
        notificationService.create('error', 'Error', `${errorMessage}`, { nzDuration: 5000 });
        return throwError(() => errorMessage);
      }
    }),
  );
};
