import { Directive, effect, ElementRef } from '@angular/core';
import { select } from '@ngxs/store';
import { ConfigurationSelectors } from '../../store/configuration/configuration.selectors';
import { Role } from '../models/role.enum';

@Directive({
  selector: '[appOnlyAdmin]',
  standalone: true,
})
export class OnlyAdminDirective {
  user = select(ConfigurationSelectors.user);

  constructor(private elementRef: ElementRef) {
    effect(() => {
      const user = this.user();
      if (user?.user?.role !== Role.Admin && user?.user?.role !== Role.SuperAdmin) {
        this.elementRef.nativeElement.style.display = 'none';
      } else {
        this.elementRef.nativeElement.style.display = 'block';
      }
    });
  }
}
