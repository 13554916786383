import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { mergeMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const login = inject(AuthService);

  if (req.url.endsWith('/token')) return next(req);
  if (req.url.endsWith('/refresh')) return next(req);

  const token = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (authService.isTokenValid()) {
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next(authReq);
  } else if (refreshToken) {
    return authService.makeRefresh(refreshToken || '').pipe(
      mergeMap((response: any) => {
        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('refreshToken', response.refresh_token);
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });

        // Pass the cloned request with the updated header to the next handler
        return next(authReq);
      }),
    );
  }
  return next(req);
};
