/**
 * dexter-logic-core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MockProjectsValues = 'Antireflective' | 'Pharmaceutical_Tests' | 'BioInk' | 'Water_Purification';

export const MockProjectsValues = {
    Antireflective: 'Antireflective' as MockProjectsValues,
    PharmaceuticalTests: 'Pharmaceutical_Tests' as MockProjectsValues,
    BioInk: 'BioInk' as MockProjectsValues,
    WaterPurification: 'Water_Purification' as MockProjectsValues
};

