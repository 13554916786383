import { Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { onlyAdminGuard } from './core/guards/only-admin.guard';
import { ErrorComponent } from './error/error/error.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { LoginComponent } from './users/login/login.component';
import { SignupComponent } from './users/signup/signup.component';

export const routes: Routes = [
  { path: '', redirectTo: 'projects', pathMatch: 'full' },
  { path: 'login', loadComponent: () => import('./users/login/login.component').then((m) => LoginComponent) },
  { path: 'signup', loadComponent: () => import('./users/signup/signup.component').then((m) => SignupComponent) },
  { path: 'error/:code', loadComponent: () => import('./error/error/error.component').then((m) => ErrorComponent) },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'profile',
        loadComponent: () => import('./profile/profile/profile.component').then((m) => ProfileComponent),
        data: {
          breadcrumb: [{ label: 'User Profile', url: null }],
        },
      },
      {
        path: 'projects',
        children: [{ path: '', loadChildren: () => import('./projects/projects.routes').then((m) => m.ROUTES) }],
      },
      {
        path: 'admin',
        canActivate: [onlyAdminGuard],
        children: [{ path: '', loadChildren: () => import('./admin/admin.routes').then((m) => m.ROUTES) }],
      },
    ],
  },
];
