import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngxs/store';
import { HotkeyModule } from 'angular2-hotkeys';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { filter, map } from 'rxjs';
import { SetBreadcrumb, SetMenuCollapse } from '../../../store/configuration/configuration.actions';
import { ConfigurationSelectors } from '../../../store/configuration/configuration.selectors';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    NzLayoutModule,
    HeaderComponent,
    NavMenuComponent,
    FooterComponent,
    HotkeyModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private store = inject(Store);
  isCollapsed = select(ConfigurationSelectors.isMenuCollapsed);

  constructor() {
    this.setBreadcrumbs();
  }

  changeCollapse(collapsed: boolean) {
    this.store.dispatch(new SetMenuCollapse(collapsed));
  }

  setBreadcrumbs() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          const items = [];
          while (route.firstChild) {
            route = route.firstChild;
            if (route.snapshot.data['breadcrumb']) {
              for (const item of route.snapshot.data['breadcrumb']) {
                items.push({ ...item });
              }
            }
          }
          return items;
        }),
      )
      .subscribe((items: { label: string; url: string | null }[]) => this.store.dispatch(new SetBreadcrumb(items)));
  }
}
