import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { select, Store } from '@ngxs/store';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { AdminSelectors } from '../../../store/admin/admin.selectors';
import { ConfigurationSelectors } from '../../../store/configuration/configuration.selectors';
import { LoadProjects } from '../../../store/project/project.actions';
import { ProjectSelectors } from '../../../store/project/project.selectors';
import { FoldersHelperService } from '../../services/folders-helper.service';

@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, NzMenuModule, NzLayoutModule, NzIconModule, NzToolTipModule, NzTreeModule],
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent {
  store = inject(Store);
  router = inject(Router);
  activeTenant = select(ConfigurationSelectors.activeTenant);
  projects = select(ProjectSelectors.projects);
  user = select(ConfigurationSelectors.user);
  folders = select(AdminSelectors.folders);
  lastProjects = computed(() => {
    return this.projects().slice(0, 5);
  });
  isCollapsed = select(ConfigurationSelectors.isMenuCollapsed);
  nodes = [];
  folderTree = computed(() => {
    const folders = (this.folders() || []).map((f: any) => ({
      ...f,
      icon: 'folder',
      title: f.name,
      key: f.id,
      expanded: true,
    }));
    return [
      {
        key: '',
        title: 'WORKSPACE',
        icon: 'partition',
        expanded: true,
        children: FoldersHelperService.buildTreeArray(folders),
      },
    ];
  });

  clickNode(event: any): void {
    const folderId = event.node.key;
    const queryParams = folderId ? { folderId } : {};
    const workspaceId = this.store.selectSnapshot(ConfigurationSelectors.activeWorkspaceId);
    const tenantId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
    this.store.dispatch(new LoadProjects(workspaceId, folderId));
    this.router.navigate(['/projects', tenantId], { queryParams });
  }

  goToAdmin() {
    const isSuperAdmin = this.store.selectSnapshot(ConfigurationSelectors.isSuperAdmin);
    if (isSuperAdmin) {
      return this.router.navigate(['/admin']);
    }
    const isAdmin = this.store.selectSnapshot(ConfigurationSelectors.isAdmin);
    if (isAdmin) {
      const organizationId = this.store.selectSnapshot(ConfigurationSelectors.activeTenant)?.id;
      return this.router.navigate(['/admin/organization', organizationId]);
    }
    return;
  }
}
