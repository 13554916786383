import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoadUserInfo, Logout } from '../../store/configuration/configuration.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private store = inject(Store);
  private http = inject(HttpClient);
  private apiUrl = environment.loginApiBaseUrl;
  private clientSecret = environment.loginClientSecret;
  private clientId = environment.loginClientId;
  private scope = environment.loginScope;
  private jwtHelper: JwtHelperService = new JwtHelperService({});
  private login$: Observable<any> | null = null;
  private refresh$: Observable<any> | null = null;
  private isLoadingUser = false;

  hasToken(): boolean {
    return !!localStorage.getItem('accessToken');
  }

  isTokenValid(): boolean {
    const accessToken = localStorage.getItem('accessToken');
    return !!accessToken && !this.jwtHelper.isTokenExpired(accessToken);
  }

  isRefreshTokenValid(): boolean {
    const refreshToken = localStorage.getItem('refreshToken');
    return !!refreshToken && !this.jwtHelper.isTokenExpired(refreshToken);
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && !this.jwtHelper.isTokenExpired(accessToken)) {
      return of({ access_token: accessToken, refresh_token: refreshToken });
    }
    if (refreshToken && !this.jwtHelper.isTokenExpired(refreshToken)) {
      if (this.refresh$) return this.refresh$;
      this.refresh$ = this.makeRefresh(refreshToken);
      return this.refresh$;
    } else {
      if (this.login$) return this.login$;
      this.login$ = this.makeLogin(credentials);
      return this.login$;
    }
  }

  makeLogin(credentials: { email: string; password: string }) {
    const formData = new URLSearchParams();
    formData.set('username', credentials.email);
    formData.set('password', credentials.password);
    formData.set('client_id', this.clientId);
    formData.set('client_secret', this.clientSecret);
    formData.set('scope', this.scope);
    formData.set('grant_type', 'password');
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http
      .post(this.apiUrl, formData, {
        headers,
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem('accessToken', response.access_token);
          localStorage.setItem('refreshToken', response.refresh_token);
          this.login$ = null;
        }),
        switchMap((response: any) => this.initUser(response.access_token)),
        catchError((error) => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          this.login$ = null;
          throw error;
        }),
        //shareReplay(1),
      );
  }

  makeRefresh(refreshToken: string) {
    const formData = new URLSearchParams();
    formData.set('refresh_token', refreshToken);
    formData.set('client_id', this.clientId);
    formData.set('client_secret', this.clientSecret);
    formData.set('grant_type', 'refresh_token');
    //formData.set('scope', 'frontend-scope');
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http
      .post(this.apiUrl, formData, {
        headers,
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem('accessToken', response.access_token);
          localStorage.setItem('refreshToken', response.refresh_token);
          this.refresh$ = null;
        }),
        switchMap((response: any) => this.initUser(response.access_token)),
        catchError((error) => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          this.refresh$ = null;
          throw error;
        }),
        //shareReplay(1),
      );
  }

  initUser(token: string): Observable<any> {
    if (!this.isLoadingUser && !this.jwtHelper.isTokenExpired(token)) {
      this.isLoadingUser = true;
      const userToken = this.jwtHelper.decodeToken(token);
      return this.store.dispatch(new LoadUserInfo(userToken)).pipe(
        tap(() => (this.isLoadingUser = false)),
        catchError((error) => {
          this.isLoadingUser = false;
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          throw error;
        }),
      );
    }
    return of();
  }

  logout(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.login$ = null;
    this.refresh$ = null;
    this.store.dispatch(new Logout());
  }
}
