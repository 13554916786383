<div class="sidebar fxColumn h-100">
  <div class="sidebar-logo outer-bottom-xxs" [class.collapsed]="isCollapsed()">
    <a [routerLink]="['/projects', activeTenant()?.id]" [queryParams]="{ folder: null }">
      @if (!isCollapsed()) {
        <span class="logo">Dexter</span>
        <!--<img src="/assets/images/TNP_logo_WHITE.png" alt="logo" />-->
      } @else {
        <span class="logo">D<span style="position: relative; top: 5px; font-weight: 400">x</span></span>
        <!--<img src="/assets/images/TNP_anagrama_WHITE.png" alt="logo" />-->
      }
    </a>
  </div>
  <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">
    <li nz-submenu nzOpen nzTitle="Projects" nzIcon="folder">
      <div class="p-1" nzTheme="dark" data-testid="folders-tree">
        <nz-tree #nzTreeComponent [nzData]="folderTree()" nzShowIcon (nzClick)="clickNode($event)"></nz-tree>
      </div>
    </li>
  </ul>
  <div class="fxFlex"></div>
  <!--<div class="separator"></div>
  <ul class="outer-bottom-micro" nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">
    <li
      nz-menu-item
      (click)="goToAdmin()"
      [nzTooltipTitle]="isCollapsed() ? 'Settings' : null"
      nzTooltipPlacement="right"
      nz-tooltip>
      <span nz-icon nzType="control"></span>
      <span>Settings</span>
    </li>
  </ul>-->
</div>
