<div>
  <div class="flex">
    <nz-steps
      class="my-6 mx-3"
      [nzCurrent]="step"
      nzDirection="vertical"
      (nzIndexChange)="step = $event"
      style="width: auto">
      <nz-step [nzTitle]="tap1Tpl"></nz-step>
      <nz-step [nzTitle]="tap2Tpl"></nz-step>
      <nz-step [nzTitle]="tap3Tpl"></nz-step>
    </nz-steps>
    <ng-template #tap1Tpl> Create your <br />experimental project </ng-template>
    <ng-template #tap2Tpl> Obtain experiment <br />recommendations </ng-template>
    <ng-template #tap3Tpl> Analyze your <br />experimental data </ng-template>
    <div class="flex-1">
      @switch (step) {
        @case (0) {
          <div style="padding:51.11% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1053730292?h=c65691cee4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=584799&portrait=0&byline=0&amp;autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="DxTER: Create your experimental project"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
        }
        @case (1) {
          <div style="padding:48.79% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1053731308?h=82cfdcc2f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0&amp;autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="DxTER: Obtain experiment recommendations"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
        }
        @case (2) {
          <div style="padding:48.79% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1053722336?h=fb26bf5dca&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0&amp;autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="DxTER: Analyze your experimental data"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
        }
      }
    </div>
  </div>
</div>
<div *nzModalFooter>
  <div>For more help, please visit our <a href="https://dxter.ai/support" target="_blank">FAQ page</a>.</div>
</div>
