import { Component, inject } from '@angular/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'app-video-help-dialog',
  standalone: true,
  imports: [NzModalModule, NzTabsModule, NzStepsModule],
  templateUrl: './video-help-dialog.component.html',
  styleUrl: './video-help-dialog.component.scss',
})
export class VideoHelpDialogComponent {
  private modal = inject(NzModalRef);
  step = 0;
}
