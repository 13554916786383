import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { Component, inject, OnInit, Signal } from '@angular/core';
import { select } from '@ngxs/store';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ConfigurationSelectors } from '../../../store/configuration/configuration.selectors';
import { PortalsBridgeService } from '../../services/portal-bridge.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NzBadgeModule, PortalModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  portalsBridge = inject(PortalsBridgeService);
  portal!: Signal<TemplatePortal | null>;

  organization = select(ConfigurationSelectors.activeTenant);
  workspace = select(ConfigurationSelectors.activeWorkspace);
  isSuperAdmin = select(ConfigurationSelectors.isSuperAdmin);

  ngOnInit(): void {
    this.portal = this.portalsBridge.footerPortal;
  }
}
