<footer class="w-full pb-2 px-3 flex justify-content-center align-items-center">
  @if (isSuperAdmin()) {
    <div class="flex justify-content-center align-items-center">
      <span class="mx-1">{{ organization()?.name || 'N/A' }}</span>
      <span class="separator mx-2"></span>
      <span class="mx-1">{{ workspace()?.name || 'N/A' }}</span>
    </div>
  }
  <span class="flex-auto">
    <!--<nz-badge nzStatus="success" nzText="Connected"></nz-badge>-->
    <ng-template [cdkPortalOutlet]="portal()"></ng-template>
  </span>
  <span>© TheNextPangea</span>
</footer>
