import { CanActivateFn } from '@angular/router';
import { select } from '@ngxs/store';
import { ConfigurationSelectors } from '../../store/configuration/configuration.selectors';
import { Role } from '../models/role.enum';

export const onlyAdminGuard: CanActivateFn = (route, state) => {
  const user = select(ConfigurationSelectors.user);
  const isAdmin = user()?.user?.role === Role.Admin || user()?.user?.role === Role.SuperAdmin;
  if (!isAdmin) {
    console.error("Can't access this route");
  }
  return isAdmin;
};
